<template>
    <r-e-dialog title="补充水电余额" :visible.sync="dialogVisible" width="710px" top="10vh" show-footer
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="90px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="合同编号" prop="contractUuid">
                    <el-select v-model="formPublish.contractUuid" placeholder="请选择" @change="contractChange">
                        <el-option v-for="item in contractList" :key="item.uuid" :label="item.contractNum"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间地址">
                    <el-input v-model="apartmentName" :disabled="true" placeholder="请输入内容" style="width: 220px;"/>
                </el-form-item>
            </div>

            <div class="flex">
                <el-form-item label="旧合同编号">
                    <el-select v-model="formPublish.oldContractUuid" placeholder="请选择" @change="oldContractChange">
                        <el-option v-for="item in contractList" :key="item.uuid" :label="item.contractNum"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间地址">
                    <el-input v-model="oldApartmentName" :disabled="true" placeholder="请输入内容" style="width: 220px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {supplementBalance, getContractListByLeasor} from "@/api/tenant-management";
import {MessageSuccess} from "@custom/message";

export default {
    name: "layer-supplement-balance",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                contractUuid: null,
                oldContractUuid: null,
            },
            rules: {
                contractUuid: [{required: true, message: '请输入记录实际水用量', trigger: 'blur'},],
            },
            loadingOptions: {
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            contractList: [],
            apartmentName: null,
            oldApartmentName: null,
        }
    },
    props: {
        tenantData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async openDialog() {
            let that = this;
            const {uuid} = that.tenantData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            getContractListByLeasor({leasorUuid: uuid}).then(res => {
                const {list} = res;
                that.contractList = list;
                that.dialogVisible = true;
            }).finally(() => loading.close());
        },
        clickSubmit() {
            let that = this;
            this.$refs['formPublish'].validate(valid => {
                if (valid) {
                    const {contractUuid, oldContractUuid} = that.formPublish;
                    const data = {contractUuid, oldContractUuid};
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    supplementBalance(data).then(res => {
                        MessageSuccess("成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.$emit('handleSearch', false);
            this.formPublish = {
                contractUuid: null,
                oldContractUuid: null,
            }
            this.dialogVisible = false;
        },
        contractChange(value) {
            console.log(value);
            const contract = this.contractList.find(({uuid}) => uuid === value);
            const {apartment: {name}} = contract;
            this.apartmentName = name;
            console.log(contract);
        },
        oldContractChange(value) {
            const contract = this.contractList.find(({uuid}) => uuid === value);
            const {apartment: {name}} = contract;
            this.oldApartmentName = name;
        }
    },
}
</script>

<style scoped>

</style>